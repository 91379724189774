<template>
  <a-card title="资料分类维护" class="CategoryMaintenance_wrapper card__wrapper">
    <div class="card_content">
      <!-- <div class="item_content">
        <div class="btn_box"></div>
        <div class="category-card">
          <div v-for="(item) in lData" :key="item.id" :class="{
            'category-item': true,
            active: item.id === lActive
          }" @click="handleActive(item.id,'lActive', 2)">
            <div class="text">{{ item.name }}</div>
            <div class="icon">
             <ArrowRightOutlined />
            </div>
          </div>
        </div>
      </div> -->
      <div class="item_content">
        <div class="btn_box">
          <a-button @click="handleAdd('lData', 'lActive', 1)">新增分类</a-button>
          <a-button @click="handleUpdate('lcData', 'lcActive', 'lData', 'lActive', 1)" :disabled="!lcActive">编辑分类
          </a-button>
          <a-button @click="handleDelete('lcActive', 1, 'lActive')" :disabled="!lcActive">删除分类</a-button>
        </div>
        <div class="category-card">
          <div v-for="(item) in lcData" :key="item.categoryId " :class="{
            'category-item': true,
            active: item.categoryId === lcActive
          }" @click="handleActive(item.categoryId,'lcActive', 2)">
            <div class="text">{{ item.name }}</div>
            <div class="icon">
              <ArrowRightOutlined />
            </div>
          </div>
        </div>
      </div>
      <div class="item_content">
        <div class="btn_box">
          <a-button @click="handleAdd('lcData', 'lcActive', 2)" :disabled="!lcActive">新增分类</a-button>
          <a-button @click="handleUpdate('cData', 'cActive', 'lcData', 'lcActive', 2)" :disabled="!cActive">编辑分类
          </a-button>
          <a-button @click="handleDelete('cActive', 2, 'lcActive')" :disabled="!cActive">删除分类</a-button>
        </div>
        <div class="category-card">
          <div v-for="(item) in cData" :key="item.categoryId" :class="{
            'category-item': true,
            active: item.categoryId === cActive
          }" @click="handleActive(item.categoryId,'cActive', 3)">
            <div class="text">{{ item.name }}</div>
            <div class="icon">
              <ArrowRightOutlined />
            </div>
          </div>
        </div>
      </div>
      <div class="item_content">
        <div class="btn_box">
          <a-button @click="handleAdd('cData', 'cActive', 3)" :disabled="!cActive">新增分类</a-button>
          <a-button @click="handleUpdate('crData', 'crActive', 'cData', 'cActive', 3)" :disabled="!crActive">编辑分类
          </a-button>
          <a-button @click="handleDelete('crActive', 3, 'cActive')" :disabled="!crActive">删除分类</a-button>
        </div>
        <div class="category-card">
          <div v-for="(item) in crData" :key="item.categoryId" :class="{
            'category-item': true,
            active: item.categoryId === crActive
          }" @click="handleActive(item.categoryId,'crActive')">
            <div class="text">{{ item.name }}</div>
            <div class="icon">
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="item_content">
        <div class="btn_box">
          <a-button @click="handleAdd('crData', 'crActive', 5)" :disabled="!crActive">新增分类</a-button>
          <a-button @click="handleUpdate('rData', 'rActive', 'crData', 'crActive', 5)" :disabled="!rActive">编辑分类
          </a-button>
          <a-button @click="handleDelete('rActive', 5, 'crActive')" :disabled="!rActive">删除分类</a-button>
        </div>
        <div class="category-card">
          <div v-for="(item) in rData" :key="item.id" :class="{
            'category-item': true,
            active: item.id === rActive
          }" @click="handleActive(item.id,'rActive')">
            <div class="text">{{ item.name }}</div>
            <div class="icon">
             <ArrowRightOutlined />
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="handleCancel" :confirmLoading="confirmLoading">
      <a-form :model="form" ref="form" :rules="rules" :wrapper-col="{span:14}" :label-col="{span:6}">
        <a-form-item prop="parentName" label="父级分类名称" v-if="visibleDisabled">
          <a-input v-model:value="form.parentName" allowClear :disabled="true"></a-input>
        </a-form-item>
        <a-form-item prop="name" label="分类名称">
          <a-input v-model:value="form.name" allowClear></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-card>
</template>

<script>
import { getCommodityList, addCommodity, updateCommodity, deleteCommodity } from '@/api/api'
import {
  ArrowRightOutlined
} from '@ant-design/icons-vue';
export default {
  name: 'CategoryMaintenance',
  components: {
    ArrowRightOutlined
  },
  data() {
    return {
      visibleDisabled: false,
      lActive: null,
      lcActive: null,
      cActive: null,
      crActive: null,
      rActive: null,
      lData: [],
      lcData: [],
      cData: [],
      crData: [],
      rData: [],
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      edit: false,
      visible: false,
      editLevel: null,
      parentId: null,
      form: {
        type: 2,
        parentName: '',
        name: ''
      },
      rules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入分类名称'
          }
        ]
      },
      confirmLoading: false
    }
  },
  computed: {
    title() {
      return this.edit ? '编辑资料分类' : '新增资料分类'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.handleGetData()
    },
    handleGetData(level = 1, parentId = null) {
      this[`loading${level}`] = true
      const l = level === 1 ? level : null
      getCommodityList({
        type: 2,
        level: l,
        parentId: parentId
      })
        .then(res => {
          if (level === 1) {
            this.lcActive = (res.data.find(item => item.categoryId === this.lcActive) || {}).categoryId
            this.lcData = res.data
          } else if (level === 2) {
            this.cActive = (res.data.find(item => item.categoryId === this.cActive) || {}).categoryId
            this.cData = res.data
          } else if (level === 3) {
            this.crActive = (res.data.find(item => item.categoryId === this.crActive) || {}).categoryId
            this.crData = res.data
          }
        })
        .finally(() => [(this[`loading${level}`] = false)])
    },
    handleActive(id, key, level) {
      if (this[key] === id) {
        return
      }
      this[key] = id
      if (!level) {
        return
      }
      if (key === 'lActive') {
        this.lcData = []
        this.lcActive = null
        this.cData = []
        this.cActive = null
        this.crData = []
        this.crActive = null
        this.rData = []
        this.rActive = null
      }
      if (key === 'lcActive') {
        this.cData = []
        this.cActive = null
        this.crData = []
        this.crActive = null
        this.rData = []
        this.rActive = null
      }
      if (key === 'cActive') {
        this.crData = []
        this.crActive = null
        this.rData = []
        this.rActive = null
      }
      if (key === 'crActive') {
        this.rData = []
        this.rActive = null
      }
      this.handleGetData(level, id)
    },
    handleAdd(data, parentId, level) {
      if (level == 1) {
        this.visibleDisabled = false
      } else {
        this.visibleDisabled = true
      }
      this.editLevel = level
      this.parentId = this[parentId]
      this.form = {
        parentId: this[parentId],
        parentName: this[data].find(item => item.categoryId === this[parentId])?.name,
        level: level,
        type: 2,
        name: '',
      }
      this.visible = true
    },
    handleUpdate(type, id, parentType, parentId, level) {
      if (level == 1) {
        this.visibleDisabled = false
      } else {
        this.visibleDisabled = true
      }
      this.editLevel = level
      this.parentId = this[parentId]
      this.edit = true
      const rowItem = this[type].find(item => item.categoryId === this[id])
      if (level != 1) {
        const parentName = this[parentType].find(item => item.categoryId === rowItem.parentId).name
        this.form = { ...rowItem, parentName, type: 2, }
      } else {
        this.form = { ...rowItem, type: 2, }
      }
      this.visible = true
    },
    handleDelete(type, level, parentType) {
      this.$confirm({
        title: '资料删除提示',
        content: '此分类下其他分类也将一并删除，确定删除此条分类嘛？',
        onOk: () => {
          deleteCommodity({ id: this[type] }).then((res) => {
            if (!res.errMessage) {
              this.$message.success('删除成功')
            }
            this[type] = null
            if (type === 'lActive') {
              this.lcData = []
              this.lcActive = null
              this.cData = []
              this.cActive = null
              this.crData = []
              this.crActive = null
              this.rData = []
              this.rActive = null
            }
            if (type === 'lcActive') {
              this.cData = []
              this.cActive = null
              this.crData = []
              this.crActive = null
              this.rData = []
              this.rActive = null
            }
            if (type === 'cActive') {
              this.crData = []
              this.crActive = null
              this.rData = []
              this.rActive = null
            }
            if (type === 'crActive') {
              this.rData = []
              this.rActive = null
            }
            this.handleGetData(level, this[parentType])
          })
        }
      })
    },
    handleOk() {
      this.$refs.form.validate().then(() => {
        const fn = this.edit ? updateCommodity : addCommodity
        this.confirmLoading = true
        fn(this.form)
          .then(res => {
            if (res.errCode != 500) {
              this.$message.success(`${this.title}成功`)
              this.handleCancel()
              this.handleGetData(this.editLevel, this.parentId)
            } else {
              this.$message.error(res.errMessage)
            }
          })
          .finally(() => {
            this.confirmLoading = false
          })
      })
    },
    handleCancel() {
      this.edit = false
      this.visible = false
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang='less'>
.CategoryMaintenance_wrapper {
  .card_content {
    display: flex;
    height: 100%;

    .item_content {
      flex: 1;
      padding-right: 24px;

      &:last-child {
        padding-right: 0px;
      }

      .btn_box {
        height: 32px;
      }
    }
  }

  .category-card {
    border: 1px solid #f0f2f5;
    margin-top: 30px;
    height: calc(100% - 60px);
    overflow: hidden auto;

    .category-item {
      height: 50px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      cursor: pointer;

      &.active {
        background: skyblue;
      }
    }
  }
}
</style>
